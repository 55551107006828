body {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

:root {
  --primary: #0a243d;
  --secondary: #8b97ae;
  --close-btn-border: #8b97ae;
  --secondary-btn-hover-BgColor: #023b89;
  --secondary-btn-hover-borderColor: #307de8;
  --white: #fff;
  --sky: #33afe3;
  --webBG: #ffff;
  --headerFooterBG: #06233f;
  --sidebar: #0a243d;
  --primaryBorder: #153655;
  --hover: #15365557;
  --save-btn: #0a243d;
  --headingBg: #11283c;
}

/* Spinner */

.loader-outer {
  background: rgb(255, 255, 255);
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  align-items: center;
  justify-content: center;
}

.loader-outer div {
  width: 100px;
}

.loader-outer.addloader {
  display: flex;
}

.spinner-container {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes audio-wave {
  0% {
    height: 10px;
    transform: translateY(0px) scaleY(1.7);
    background: #1c0f80;
  }

  25% {
    height: 40px;
    transform: translateY(-5px) scaleY(1.7);
    background: #1c0f80;
  }

  50% {
    height: 10px;
    transform: translateY(0px) scaleY(1.7);
    background: #1c0f80;
  }

  100% {
    height: 10px;
    transform: translateY(0px) scaleY(1.7);
    background: #1c0f80;
  }
}

.sound_wave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 50px;
}

.sound_wave span {
  height: 18px;
  width: 5px;
  /* Adjust the width here to make it smaller */
  display: block;
  border-radius: 8px;
  background: #1c0f80;
  animation: audio-wave 1.7s infinite ease-in-out;
  /* Faster animation (1.7s) */
}

.sound_wave span:nth-child(2) {
  left: 11px;
  background: #1c0f80;
  animation-delay: 0.2s;
}

.sound_wave span:nth-child(3) {
  left: 22px;
  animation-delay: 0.4s;
}

.sound_wave span:nth-child(4) {
  left: 33px;
  animation-delay: 0.6s;
}

.sound_wave span:nth-child(5) {
  left: 44px;
  animation-delay: 0.8s;
}

.sound_wave span:nth-child(6) {
  left: 55px;
  animation-delay: 1s;
}

.loaderContainer {
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 1;
}

/* Spinner */

.container {
  display: flex;
  height: 100vh;
  background-color: #f2f2f2;
}

.left-side {
  width: 50%;
  padding: 50px;
  background-color: white;
  border-right: 1px solid #ddd;
}

.right-side {
  overflow: hidden;
  width: 45%;
  background: rgb(245, 189, 255);
  background-image: linear-gradient(130deg,
      hsl(291deg 100% 87%) 0%,
      hsl(286deg 98% 83%) 11%,
      hsl(279deg 98% 78%) 17%,
      hsl(273deg 96% 74%) 21%,
      hsl(267deg 95% 69%) 25%,
      hsl(261deg 93% 65%) 29%,
      hsl(256deg 92% 60%) 35%,
      hsl(250deg 91% 56%) 43%,
      hsl(244deg 90% 51%) 58%,
      hsl(238deg 88% 47%) 100%);
  background-position: center;
  background-size: cover;
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}

.logo h1 {
  font-size: 3em;
  color: #673ab7;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.title h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.title p {
  font-size: 1em;
  color: #673ab7;
}

.input-field {
  margin-bottom: 20px;
}

.input-field label {
  display: block;
  margin-bottom: 5px;
}

.input-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.remember {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.remember input {
  margin-right: 10px;
}

button {
  background-color: #673ab7;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register {
  text-align: center;
  margin-top: 20px;
}

.register p {
  color: #673ab7;
  font-size: 1em;
}

.image {
  position: relative;
}

.image img {
  position: absolute;
  top: 100%;
  left: 100%;
  /* height: 100%; */
  transform: translate(-50%, -50%);
}

.main-grid {
  margin: 0 auto;
  width: 50%;
  max-width: 50%;
  height: 60vh;
  background: #f8f9fd;
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  position: absolute;
  top: 50%;
  left: 50%;
  align-content: center;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.login_container {
  width: 50%;
  word-wrap: break-word;
  font-weight: 600;
}

.login_image_container {
  background-image: linear-gradient(130deg, hsl(291deg 100% 87%) 0%, hsl(286deg 98% 83%) 11%, hsl(279deg 98% 78%) 17%, hsl(273deg 96% 74%) 21%, hsl(267deg 95% 69%) 25%, hsl(261deg 93% 65%) 29%, hsl(256deg 92% 60%) 35%, hsl(250deg 91% 56%) 43%, hsl(244deg 90% 51%) 58%, hsl(238deg 88% 47%) 100%);
  background-size: cover;
  width: 50%;
  height: 100%;
  background-position: left;
  border-radius: 0px 10px 10px 0px;
}

.logo_img_container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo_img {
  width: 250px;
  height: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Dashboard Layout */

.topbar-appbar {
  background: var(--headerFooterBG) !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid var(--headerFooterBG);
  top: -16px;
  left: 0px;
}

.topbar-container {
  max-width: 100% !important;
}

.topbar-container p.profile-title {
  text-align: left;
  letter-spacing: 0px;
  color: var(--white);
  white-space: nowrap;
  opacity: 1;
  margin-left: 10px;

  font-size: 14px;
}

.topbar-appbar-right button,
.topbar-appbar-right button:focus,
.topbar-appbar-right button:active {
  background: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  position: relative;
}

.topbar-appbar-right button::after {
  content: "";
  position: absolute;
  right: 0px;
}

.customer-item {
  @include d-flex-column-align;
  @include background;
}

.customer-list {
  @include background;
  min-height: calc(100vh - 170px);
  padding: 5px 8px;
}

.customer-list .MuiGrid-root {
  display: flex;
}

.customer-card {
  @include box-border;
}

.customer-link {
  text-decoration: none !important;
  width: calc(100% / 7 - 30px);
  margin: 15px !important;
}

.footer {
  width: 100%;
  color: var(--secondary);
  background: var(--headerFooterBG);
  padding: 10px 0px 10px 0px;
  border-top: 1px solid var(--headerFooterBG);
  align-items: flex-start;
}

.mainsection {
  background: #041c32;
  padding: 15px;
  @include height;
}

.tabitemouter button {
  background: transparent;
  border: 1px solid #153655;
  border-radius: 7px 7px 0px 0px !important;
  text-transform: capitalize;
  font-size: 20px;
  min-width: 150px;
  transition: 0.5s;
}

.tabitemouter button.Mui-selected,
.tabitemouter button:hover {
  background: #fff;
  color: #041c32;
  border-color: #fff !important;
}

.css-3sx0hq-MuiTabs-indicator {
  background-color: #fff !important;
}

.borderdiv {
  border: 1px solid #153655;
  border-top: 0px;
}

.userdetailsinner {
  color: #fff;
  margin-bottom: 50px;
}

.userdetailsinner label {
  font-size: 22px;
  margin-bottom: 3px !important;
  display: block;
  text-transform: capitalize;
}

#simple-tabpanel-1>div {
  padding: 0px;
}

.sitetable {
  background: transparent !important;
}

.sitetable tr th,
.sitetable tr td {
  border-color: #153655;
  vertical-align: top;
}

.text-center {
  text-align: center !important;
}

.border-bottom0 {
  border-bottom: 0px !important;
}

.innertable tr th {
  padding-bottom: 2px;
  font-size: 13px;
}

.sitemap iframe {
  padding: 15px;
  border-radius: 5px;
  overflow: hidden;
  width: 100% !important;
  box-sizing: border-box;
}

.paginationouter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px;
}

.paginationouter ul li .Mui-selected {
  color: #fff;
}

.customer-list .pagination-grid {
  justify-content: center !important;
  align-items: center;
  margin: 25px 0px;
}

.customer-list .pagination-grid button {
  background: #fff;
  color: #041c32;
}

.customer-list .pagination-grid button.Mui-selected {
  background: #307de8 !important;
}

a.site-link {
  color: #fff;
  text-decoration: none;
}

h2.avatertext {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  border: 4px solid var(--secondary);
  margin: 0px;
  font-size: 27px;
  font-weight: bold;
}

.customer-link h5 {
  margin-bottom: 8px;
}

.input-search {
  background: #0A243D 0% 0% no-repeat padding-box;
  border-radius: 4px !important;
  width: 313px;
  height: 43px;
}

.input-search div {
  position: absolute;
  right: 0px;
  background: #fff;
  width: 50px;
  top: 0px;
  bottom: 0px;
  min-height: 41px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
  z-index: 100;
  cursor: pointer;
}

.searchouterright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 15px 0px 10px;
}

.pageheading h3 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.input-search div svg path {
  fill: #031c31;
}

.leaflet-popup-content p {
  margin: 9px 0px !important;
}

.leaflet-popup-content {
  margin: 0px 12px !important;
}

.tabinnerbox {
  display: flex;
  justify-content: space-between;
}

.tabinnerbox .searchrighttab {
  position: relative;
  top: -5px;
  display: flex;
  justify-content: space-between;
}


.sitemapouter iframe {
  width: 100% !important;
}

.siteview img {
  width: 100%;
}

.weblodar {
  position: fixed;
  background: var(--webBG);
  z-index: 1000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weblodar img {
  width: 150px;
}

.pageheading h2 {
  font-size: 32px;
  font-weight: 500;
}




.form-control {
  margin-top: 2px !important;
}

.customerform .btn-group {
  text-align: right;
  width: 100%;
}

.customer-form-btn {
  background: var(--secondary) !important;
  margin-left: 15px !important;
  color: #041c32 !important;
  border: 1px solid var(--secondary) !important;
}

.customer-form-btn:hover {
  color: var(--secondary) !important;
  background: transparent !important;
}

.customer-form-btn.btn-outlined {
  background: transparent !important;
  color: var(--secondary) !important;
}

.customer-form-btn.btn-outlined:hover {
  background: var(--secondary) !important;
  color: #041c32 !important;
}

.alert-head {
  position: fixed;
  z-index: 9999;
  top: 8px;
  width: 20% !important;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  min-width: 270px;
}

.alert-msg {
  background: rgba(0, 0, 0, 0.87) !important;
  opacity: 1;
  color: #fff !important;
}


.addcustomerrightpart {
  display: flex;
  align-items: center;
}

.addcustomerrightpart .btn.customer-form-btn,
.cancelBtn .btn.customer-form-btn {
  padding: 10px 10px;
  text-decoration: none;
  border-radius: 5px;
  text-transform: capitalize;
  line-height: 20px;
}

.addcustomerrightpart .btn.customer-form-btn:hover,
.cancelBtn .btn.customer-form-btn:hover {
  background: transparent;
  color: var(--secondary);
}

.loader-outer {
  background: var(--webBG);
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  align-items: center;
  justify-content: center;
}

.loader-outer img {
  width: 100px;
}

.loader-outer.addloader {
  display: flex;
}


.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  height: 70px;
}

.sidebar-title>span {
  display: none;
}

.sidebar-logo {
  width: 45%;
}

.sidebar-brand {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
  margin: 0px;
}

.sidebar-list-item>a>div>svg {
  position: relative;
  top: 6px;
  margin-right: 7px;
}

.subMenu a svg {
  position: relative;
  top: 4px;
  margin-right: 7px;
}

.sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
}

.sidebar-list-item:hover {
  background-color: var(--hover);
  cursor: pointer;
}

.sidebar-list-active {
  background-color: var(--hover);
}

.sidebar-list-item>a {
  text-decoration: none;
  color: var(--secondary);
  font-size: 14px;
}

.sidebar-list-item .subMenu {
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  padding: 12px;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}


/* Main  */
.main-container {
  overflow-y: auto;
  padding: 0px 20px;
  background: var(--webBG);
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.main-cards {
  display: flex;
  margin: 15px 0px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.main-cards .card {
  width: calc(100% / 3 - 15px);
  margin-right: 15px;
  padding: 20px;
  color: #ffff;
  background: var(--primary);
  border: 1px solid var(--primaryBorder);
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.card-inner>.card_icon {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

.header-right {
  position: absolute;
  right: 25px;
  display: flex;
  color: #263043;
}

.header-right .username {
  font-size: 20px;
  text-transform: uppercase;
  padding: 5px 10px;
}

.header-right svg {
  font-size: 30px;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  right: 0px;
  top: 35px;
  font-size: 18px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  z-index: 1;
  color: black;
  float: left;
}

.dropdown-content:after {
  content: " ";
  position: absolute;
  right: 4px;
  top: -6px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #f9f9f9;
  ;
}

.dropdown-content:hover {
  cursor: pointer;
}

.loader-outer {
  background: rgb(3 28 49 / 66%);
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  align-items: center;
  justify-content: center;
}

.loader-outer img {
  width: 100px;
}

.loader-outer.addloader {
  display: flex;
}

ul.submenu {
  padding: 0px;
  list-style: none;
  background: transparent;
}

ul.submenu li {
  background: transparent;
  padding: 10px 8px 10px 35px;
}

.navLink {
  text-decoration: none;
  color: #9e9ea4;
}


.search {
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
}

.search input {
  padding: 7.5px 14px;
}

.search-icon {
  position: absolute;
  top: 5px;
  right: 7px;
}

.action-btn {
  padding: 5px 3px !important;
  min-width: auto !important;
}

.alert-head {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 10000;
}

.imageDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px !important;
  object-fit: contain;
  gap: 12px !important;
}

.imageDiv div {
  display: flex;
}

.imageDiv div svg {
  top: 0px;
  left: 3px;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 5px;
  font-size: 20px;
  background-color: red;
  color: var(--secondary);
}

.preview-img {
  max-width: 100px;
  max-height: 100px;
}

.accessory_img input {
  font-size: 13px !important;
}

.web-logo {
  max-width: 200px;
  margin-bottom: 30px;
  background: var(--white);
  padding: 5px;
  border-radius: 5px;
}

.pwd-icon {
  position: absolute;
  right: 10px;
  top: 34px;
}

.user-pwd-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #fff;
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 8px;
}

span.password-login {
  position: relative;
  display: inline-block;
  width: 100%;
}

.layout-outer {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 66px);
  /* min-height: calc(100vh - 111px); */
}

.layout-outer #sidebar {
  min-width: 250px;
  background: var(--sidebar);
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  overflow-x: hidden;
  position: relative;
  z-index: 100;
  bottom: 0px;
  margin-left: 0px;
}

.openSidebar {
  margin-left: -250px !important;
}

.main-container {
  width: 100%;
  padding: 10px 20px 0px;
}

.logo-img {
  width: 100px;
  padding: 2px 5px;
  background-color: #fff !important;
  border-radius: 5px;
}

/* Table */
/* table.table-outers tbody tr td:last-child,
.table-outers table tbody tr td:last-child {
  display: flex;
} */
.table-outers {
  border-radius: 0px !important;
  box-shadow: none !important;
}

/* .table-outers::-webkit-scrollbar {
  display: none;
} */

.table-head {
  display: table-header-group !important;
}

.closeicon {
  position: absolute;
  right: 35px;
  top: 25px;
  cursor: pointer;
}

.save-btn {
  background: var(--save-btn) !important;
  border: 1px solid var(--save-btn-border) !important;
  color: var(--white) !important;
  padding: 5px 20px !important;
  font-size: 12px !important;
}

.close-btn {
  background: var(--close-btn-border) !important;
  border: 1px solid var(--close-btn-border) !important;
  color: #ffff !important;
  padding: 5px 20px !important;
  font-size: 12px !important;
}

.inner-headings,
.inner-headings span {
  color: #fff;
  font-size: 24px !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.flex-boxinner {
  display: flex;
  width: 300px !important;
}

.flex-boxinner button {
  margin-left: 15px;
  white-space: nowrap;
}

.search {
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
}

.search input {
  padding: 8px 14px;
}

.search-icon {
  position: absolute;
  top: 6px;
  right: 7px;
}

.karuModal {
  max-height: 90vh;
  overflow: auto;
  /* max-width: 95%; */
}

.form-outers,
.karuModal {
  position: relative;
}

.form-outers .closeicon,
.karuModal .closeicon {
  position: absolute;
  right: 30px;
  /* color: #fff; */
  /* top: 18px; */
  cursor: pointer;
}

.mircro-service-icon {
  color: inherit;
  cursor: pointer;
}
.mircro-service-icon.MuiSvgIcon-root {
  font-size: 1.7rem !important;
}

.additionalServerInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  width: 100% !important;
}

/* Table Body td */
.table-body-cell{
  font-size: small !important;
  line-height: 1.2 !important;
}
/* Table Head td*/
.table-head-cell{
  font-size: 14px !important;
  line-height: 1.2 !important;
}

.server-notes{
  width: 45%;
  padding: 8px 0px !important;
  box-shadow: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.additional-info{
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem !important;
}
.table-action-ssh-btn {
  color: white;
  background: rgb(0, 102, 255);
  border: none;
  cursor: pointer;
  padding: 10px 5px;
  font-weight: 600;
  width: 80%;
}

.terminal-modal {
  width: 80%;
  max-width: 800px;
  height: 80%;
  max-height: 600px;
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.terminal-modal h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.terminal-modal button {
  color: #555;
  background: transparent;
  border: none;
  cursor: pointer;
}

.tanle-container-box{
  margin-left: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .main-grid {
    width: 95%;
    max-width: 95%;
    height: auto;
    padding: 20px;
  }

  .login_container {
    width: 100%;
    word-wrap: break-word;
  }

  .login_image_container {
    display: none;
  }
  .additionalServerInfo {
    flex-direction: column;
  }
  .additional-info{
    width: 100% !important;
  }
  .server-notes{
    width: 100% !important;
  }
  .table-action-ssh-btn {
    width: 100% !important;
  }
  .inner-headings,
  .inner-headings span {
    color: #fff;
    font-size: large !important;
    font-weight: 600 !important;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 768px) {
  .main-grid {
    width: 80%;
    max-width: 80%;
    height: 60vh;
  }

  .login_container {
    width: 100%;
    word-wrap: break-word;
  }

  .karuModal {
    max-height: 90vh;
  }

  .karuModal {
    min-width: 80%;
    transform: initial !important;
    top: 10px !important;
    left: 10px !important;
    right: 10px;
    max-height: 85%;
    overflow: auto;
    max-width: 85%;
  }
  .additional-info{
    width: 100% !important;
  }
  .server-notes{
    width: 100% !important;
  }
  .table-action-ssh-btn {
    width: 100% !important;
  }
  .tanle-container-box{
    margin-left: 0.4rem;
  }
}